<template>
  <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
    <div class="MBtextcont">
      <h3>Мы прошли с вами непростой путь от пачки пластика до собранной куклы! 2-ой важный шаг в нашей работе - это создание костюма!  </h3>
      
      <div class="MBimage-cont">
        <div class="MBimage-big" :class='$mq'>
          <KImage
            @click="showImage(image1)"
            :src="image1"
            alt="Art doll" width="100%"/>
        </div>

        <div class="MBimage-cont-column">
          <div class="MBimage-small" :class='$mq'>
            <KImage             @click="showImage(image2)"
                                :src="image2"  alt="Art doll" width="100%"/>
          </div>
          <div class="MBimage-small" :class='$mq'>
            <KImage @click="showImage(image3)" :src="image3"  alt="Art doll" width="100%"/>
          </div>
        </div>
      </div>

      <p class="nomargin">Вас ждёт:</p>
        <ul>
          <li>Моделирование и пошив платья с подкладом</li>
          <li>Все тонкости работы с тонкой и сыпучей тканью</li>
          <li>Создание рукава на высоком манжете</li>
          <li>Лёгкие и совершенно простые в исполнении туфельки из батиста) </li>
        </ul>
      <p>Просто и эффектно вот мой девиз! Уже через пару недель вы сможете похвастаться совершенно готовой куклой! Это ли не чудо? Конечно, оно ) Жду вас на первом уроке по костюму!</p>

      <KRoundedButton @click="buyChapters" class="MBbutton" :class='$mq' color="#d9706b" text-color="white" >
          Купить продолжение за 1500&#8381;
          <template v-slot:icon>
              <KIHandFlower icon-color="#FFF" width="40pt" height="40pt"/>
          </template>
      </KRoundedButton>

      <h3>Спец предложение для самых продвинутых и смелых )</h3>
      <p>А что, если нам замахнусь на съемный костюм? Готовы рискнуть нервами и временем 😜 и создать в итоге костюм, которым будете гордиться? )</p>

      <div class="" :class='$mq'>
        <KImage @click="showImage(image4)" :src="image4"  alt="Art doll" width="100%"/>
      </div>

      <div class="MBimage-cont second">
        <div class="MBimage-small second" :class='$mq'>
          <KImage @click="showImage(image5)" :src="image5"  alt="Art doll" width="100%"/>
        </div>
        <div class="MBimage-small second" :class='$mq'>
          <KImage @click="showImage(image6)" :src="image6"  alt="Art doll" width="100%"/>
        </div>
        <div class="MBimage-small second" :class='$mq'>
          <KImage @click="showImage(image7)" :src="image7"  alt="Art doll" width="100%"/>
        </div>
      </div>

      <p>Получите навык работы с историческим платьем в стиле ампир 19 века и создадите таинственный образ Наташи Ростовой или Эленн Безуховой, Эммы или Элизабет Беннет... Великий век, неповторимые женские образы)</p>
      <p>Все это можно сделать по курсу «Давай оденем куклу», а это совсем другой уровень подхода к своей работе! Всего за 2650&#8381; вместо 3500&#8381;</p>

      <KRoundedButton @click="buyCourse" class="MBbutton" :class='$mq' color="#d9706b" text-color="white" >
          Купить курс за 2650&#8381;
          <template v-slot:icon>
              <KIHandFlower icon-color="#FFF" width="40pt" height="40pt"/>
          </template>
      </KRoundedButton>
    </div>
    
  </KCourse>
</template>

<script>
import MBCourseCostume12 from "@/components/Membership/Costume/MBCourseCostume12";
import chapters from './data.js';
import KRoundedButton from "../../Widgets/KRoundedButton";
import KImage from "../../Widgets/KImage";
import {createOrder} from "@/service/api";

import image1 from "@/assets/membersh/bodoirV2/Carousel/P4220149.jpg";
import image2 from "@/assets/membersh/bodoirV2/Carousel/P4220145.jpg";
import image3 from "@/assets/membersh/bodoirV2/Carousel/P4220155.jpg";

import image4 from "@/assets/membersh/bodoirV2/Carousel/1.jpg";
import image5 from "@/assets/membersh/bodoirV2/Carousel/2.jpg";
import image6 from "@/assets/membersh/bodoirV2/Carousel/3.jpg";
import image7 from "@/assets/membersh/bodoirV2/Carousel/4.jpg";



export default {
  name: "MBCourseHead",
  components: {
    MBCourseCostume12,
    KRoundedButton,
    KImage,
  },
  data() {
    return {
      items: chapters,
      image1: image1,
      image2: image2,
      image3: image3,
      image4: image4,
      image5: image5,
      image6: image6,
      image7: image7,
    }
  },
  methods: {
    buyChapters() {
        createOrder('8', 'chapter_86')
          .then(response => {
            let orderId = response.data.data.order_id
            this.$router.push('/order/'+orderId)
          })
    },
    buyCourse() {
      createOrder('4', 'course')
        .then(response => {
          let orderId = response.data.data.order_id
          this.$router.push('/order/'+orderId)
        })
    },
    showImage(src) {
      this.$swal.fire({
        // width: '80%',
        height: '90%',
        background: '#eae2e0',
        html: '<img width="100%" src="'+src+'"/>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
      })
    },
  }
}
</script>

<style scoped>
.MBimage-cont {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  cursor: pointer;
}
  .MBimage-cont.second {
    padding-top: 0.7em;
  }
  .MBimage-big {
    width: 66%;
  }

  .MBimage-cont-column {
    width: 32%;
  }
    .MBimage-small {
      padding-bottom: 0.5em;
    }
    .MBimage-small.second {
      width:32%;
    }
</style>
<template>
  <KCourse loader-class="MBcont" :course-id="courseId" title="Съемный костюм XIX в." :items="items">

    <VideoView video-id="eca15332736f40a8a2c119a2b77e878d"/>

  </KCourse>
</template>

<script>
import VideoView from "../../Widgets/VideoView";
import KCourse from "../../Widgets/KCourse";

export default {
  name: "MBCourseHead",
  props: {
    courseId: {
      default: "4"
    },
    items: {
      type: Array,
      default: [
        {title: "Подготовка", url: "/mycourses/costume/1"},
        {title: "Снимаем выкройку", url: "/mycourses/costume/2"},
        {title: "Моделируем", url: "/mycourses/costume/3"},
        {title: "Ткань. Раскрой", url: "/mycourses/costume/4"},
        {title: "Нижнее белье", url: "/mycourses/costume/5"},
        {title: "Шьем полочку", url: "/mycourses/costume/6"},
        {title: "Дошиваем платье", url: "/mycourses/costume/7"},
        {title: "Красное платье", url: "/mycourses/costume/8"},
        {title: "Делаем обувь", url: "/mycourses/costume/9"},
        {title: "Ручные швы", url: "/mycourses/costume/10"},
        {title: "Машинные швы", url: "/mycourses/costume/11"},
        {title: "Утюжка", url: "/mycourses/costume/12"},
      ]
    }
  },
  components: {
    VideoView,
    KCourse,
  },
}
</script>